<template>
  <div>
    <div class="text-center">
      <img src="../assets/images/img2.png" class="img2" />
    </div>
    <div class="size-58 text-bold text-center mt-120 mb-110">
      {{ $t("我们设定目标并征服他们") }}
    </div>
    <div style="width: 100vw; overflow-x: hidden">
      <div class="target" id="targetElement">
        <div class="flex align-center">
          <div class="left-gap flex-shrink"></div>

          <div class="target-box pos-rel flex-shrink">
            <img src="../assets/images/1.png" class="target-img" />
            <div class="color-green size-20">{{ $t("第一阶段") }}</div>
            <div class="size-32 text-bold mt-35">
              {{ $t("架构搭建与基础设施开发") }}
            </div>
            <div class="target-line mt-40"></div>
            <div class="flex align-center mt-40">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("跨链机枪池原型开发") }}</div>
            </div>
            <div class="flex align-center mt-30">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("借贷协议初始开发") }}</div>
            </div>
            <div class="flex align-center mt-30">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("预言机网络测试") }}</div>
            </div>
            <div class="flex align-center mt-30">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("平台 UI/UX 设计") }}</div>
            </div>
          </div>

          <div class="target-box pos-rel flex-shrink">
            <img src="../assets/images/2.png" class="target-img" />
            <div class="color-green size-20">{{ $t("第二阶段") }}</div>
            <div class="size-32 text-bold mt-35">
              {{ $t("核心功能上线与社区发展") }}
            </div>
            <div class="target-line mt-40"></div>
            <div class="flex align-center mt-40">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("跨链机枪池上线") }}</div>
            </div>
            <div class="flex align-center mt-30">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("流动性挖矿计划启动") }}</div>
            </div>
            <div class="flex align-center mt-30">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("预言机网络上线") }}</div>
            </div>
            <div class="flex align-center mt-30">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("社区激励计划") }}</div>
            </div>
          </div>

          <div class="target-box pos-rel flex-shrink">
            <img src="../assets/images/3.png" class="target-img" />
            <div class="color-green size-20">{{ $t("第三阶段") }}</div>
            <div class="size-32 text-bold mt-35">
              {{ $t("生态扩展与合作伙伴关系建立即") }}
            </div>
            <div class="target-line mt-40"></div>
            <div class="flex align-center mt-40">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("跨链资产支持扩展") }}</div>
            </div>
            <div class="flex align-center mt-30">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("借贷生态完善") }}</div>
            </div>
            <div class="flex align-center mt-30">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("DAO 治理机制上线") }}</div>
            </div>
            <div class="flex align-center mt-30">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("建立战略合作伙伴关系") }}</div>
            </div>
          </div>

          <div class="target-box pos-rel flex-shrink">
            <img src="../assets/images/4.png" class="target-img" />
            <div class="color-green size-20">{{ $t("第四阶段") }}</div>
            <div class="size-32 text-bold mt-35">
              {{ $t("全球扩展与未来创新") }}
            </div>
            <div class="target-line mt-40"></div>
            <div class="flex align-center mt-40">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("全球市场推广") }}</div>
            </div>
            <div class="flex align-center mt-30">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("NFT 集成及扩展") }}</div>
            </div>
            <div class="flex align-center mt-30">
              <img src="../assets/images/right.png" class="img-20 mr-12" />
              <div class="size-20">{{ $t("高级 DeFi 策略工具") }}</div>
            </div>
          </div>

          <div class="right-gap flex-shrink"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
export default {
  name: "OurTarget",
  props: {},
  data() {
    return {};
  },
  mounted() {
    const scrollContainer = document.querySelector("#targetElement");

    const ps = new PerfectScrollbar(scrollContainer, {
      suppressScrollX: false, // enable horizontal scroll
      suppressScrollY: true, // enable vertical scroll
      maxScrollbarLength: 480,
    });

    // add mouse wheel event listener
    scrollContainer.addEventListener("wheel", (e) => {
      e.preventDefault();
      const delta = Math.abs(e.wheelDelta) > 0 ? e.wheelDelta : -e.deltaY;
      scrollContainer.scrollLeft -= delta / 4; // adjust scrolling speed
    });
  },
  methods: {},
};
</script>

<style lang="scss">
.target {
  width: 100vw;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 0.5rem;
}

.target-box {
  max-width: 80vw;
  width: 4.4rem;
  border-radius: 0.4rem;
  background-color: #1f211f;
  padding: 0.4rem;
  margin-right: 0.4rem;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0.01rem 0.01rem 0.05rem #666666;
  }

  .target-img {
    width: 0.68rem;
    height: 0.68rem;
    position: absolute;
    top: 0;
    right: 0;
  }

  .target-line {
    width: 1.4rem;
    height: 2px;
    background-color: #2e302e;
  }
}

.left-gap {
  width: 40vw;
  height: 2rem;
}
.right-gap {
  width: 0.4rem;
  height: 2rem;
}
</style>