<template>
  <div>
    <div class="text-center">
      <img src="../assets/images/img2.png" class="img2" />
    </div>
    <div class="size-58 text-bold text-center mt-120 mb-110">
      {{ $t("亮点优势") }}
    </div>

    <div class="main-padding">
      <div class="flex">
        <div class="flex-1 flex adv">
          <img
            src="../assets/images/icon1.png"
            class="mr-20 flex-shrink adv-img"
          />
          <div class="flex-1">
            <div class="size-24 mb-10">{{ $t("无需许可且开放") }}</div>
            <div class="size-18 color-6d">
              {{ $t("可以访问互联网的每个人都可以参加。") }}
            </div>
          </div>
        </div>
        <div class="flex-1 flex ml-10 adv">
          <img
            src="../assets/images/icon2.png"
            class="mr-20 flex-shrink adv-img"
          />
          <div class="flex-1">
            <div class="size-24 mb-10">{{ $t("非保管") }}</div>
            <div class="size-18 color-6d">
              {{ $t("最小的信任成本，用户始终拥有其数字资产的所有权。") }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex mt-60">
        <div class="flex-1 flex adv">
          <img
            src="../assets/images/icon3.png"
            class="mr-20 flex-shrink adv-img"
          />
          <div class="flex-1">
            <div class="size-24 mb-10">{{ $t("开源") }}</div>
            <div class="size-18 color-6d">
              {{
                $t("任何人都可以与 613协议 集成，并在协议之上构建自己的产品。")
              }}
            </div>
          </div>
        </div>
        <div class="flex-1 flex ml-10 adv">
          <img
            src="../assets/images/icon4.png"
            class="mr-20 flex-shrink adv-img"
          />
          <div class="flex-1">
            <div class="size-24 mb-10">{{ $t("DAO自治") }}</div>
            <div class="size-18 color-6d">
              {{ $t("B613代币可为治理投票流程提供支持。") }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex mt-60">
        <div class="flex-1 flex adv">
          <img
            src="../assets/images/icon5.png"
            class="mr-20 flex-shrink adv-img"
          />
          <div class="flex-1">
            <div class="size-24 mb-10">{{ $t("信息透明与隐私保护") }}</div>
            <div class="size-18 color-6d">
              {{ $t("在613协议中，信息高度透明。") }}
            </div>
          </div>
        </div>
        <div class="flex-1 flex ml-10 adv">
          <img
            src="../assets/images/icon6.png"
            class="mr-20 flex-shrink adv-img"
          />
          <div class="flex-1">
            <div class="size-24 mb-10">{{ $t("交易抗审查性") }}</div>
            <div class="size-18 color-6d">
              {{
                $t(
                  "613协议系统中确保交易是不可更改的，底层网络无法被中心化机构关闭。"
                )
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex mt-60 adv">
        <img
          src="../assets/images/icon7.png"
          class="mr-20 flex-shrink adv-img"
        />
        <div class="flex-1">
          <div class="size-24 mb-10">{{ $t("无障碍资金跨境流通") }}</div>
          <div class="size-18 color-6d">
            {{
              $t("613协议建立新的货币金融体系, 为资金的流转提供了便捷通道。")
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvantAge",
};
</script>

<style lang="scss">
.adv-img {
  transition: all 0.3s;
}
.adv:hover {
  .adv-img {
    transform: scale(1.1);
  }
}
@media (min-width: 768px) {
  .adv-img {
    width: 0.88rem;
    height: 0.88rem;
  }
}
@media (max-width: 768px) {
  .adv-img {
    width: 0.55rem;
    height: 0.55rem;
  }
}
</style>