<template>
  <div class="main-padding">
    <div class="text-center">
      <img src="../assets/images/img2.png" class="img2" />
    </div>
    <div class="size-58 text-bold text-center mt-120 mb-110">
      {{ $t("代币经济学") }}
    </div>

    <div class="flex">
      <div class="coin-box flex-1 mr-20">
        <img src="../assets/images/coin1.png" class="coin-img flex-shrink" />
        <div class="">
          <div class="size-26 text-bold cointitle">{{ $t("价值通证") }}</div>
          <div class="size-18 mt-20">
            {{ $t("B613是613协议中的唯一价值通证") }}
          </div>
        </div>
      </div>
      <div class="coin-box flex-1">
        <img src="../assets/images/coin2.png" class="coin-img flex-shrink" />
        <div class="flex-1">
          <div class="size-26 text-bold cointitle">{{ $t("通证获取") }}</div>
          <div class="size-18 mt-20">
            {{ $t("节点奖励 | 空投 | 协议挖矿 | 交易获取") }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-30">
      <div class="coin-box flex-1 mr-20">
        <img src="../assets/images/coin3.png" class="coin-img flex-shrink" />
        <div class="flex-1">
          <div class="size-26 text-bold cointitle">{{ $t("燃烧/销毁") }}</div>
          <div class="size-18 mt-20">
            {{ $t("持续销毁只要来自于手续费销毁、质押销毁等") }}
          </div>
        </div>
      </div>
      <div class="coin-box flex-1">
        <img src="../assets/images/coin4.png" class="coin-img flex-shrink" />
        <div class="flex-1">
          <div class="size-26 text-bold cointitle">{{ $t("通证生态") }}</div>
          <div class="size-18 mt-20">
            {{ $t("支付、抵押、借贷、交易、挖矿、兑换等") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoinKnowledge",
};
</script>

<style lang="scss">
.coin-box {
  border-radius: 0.4rem;
  background-color: #1f211f;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .coin-img {
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.3rem;
  }
}

.coin-box:hover {
  .coin-img {
    animation: coinImg 3s linear infinite;
  }
}
@media (max-width: 768px) {
  .cointitle {
    margin-top: 0.1rem;
  }
}

@keyframes coinImg {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-0.1rem);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0.1rem);
  }
  100% {
    transform: translateY(0);
  }
}
</style>