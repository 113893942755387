<template>
  <div class="main-padding top tophead pos-rel">
    <div class="">
      <div class="top-title flex-shrink">
        DeFi+613 {{ $t("协议") }}<br />= {{ $t("无限未来") }}
      </div>
      <div class="flex flex-wrap mt-60">
        <div
          class="top-btn ani-move cur"
          @click="delayFun(jump, webInfo.pancakeswap_buy)"
        >
          {{ $t("在Pancakeswap上购买") }}
        </div>
        <div class="top-btn ani-move cur" @click="delayFun(jumpWhitePaper)">
          {{ $t("白皮书") }}
        </div>
        <div
          class="top-btn ani-move cur"
          @click="delayFun(jump, webInfo.audit)"
        >
          {{ $t("审计") }}
        </div>
        <div class="top-btn ani-move cur" @click="delayFun(jumpDocument)">
          {{ $t("文档") }}
        </div>
        <div class="top-btn ani-move cur" @click="delayFun(jumpXieyi)">
          {{ $t("协议玩法") }}
        </div>
        <div class="top-btn ani-move cur" @click="delayFun(jumpJieDian)">
          {{ $t("节点权益") }}
        </div>
        <!-- <div class="top-btn ani-move cur" ref="xydz" @click="copydata('0x6b05b435e4240E606a841d26dBB54ad78055B613')">
          613 {{ $t("协议地址") }}
        </div>
        <div class="top-btn ani-move cur" ref="hydz" @click="copydata('0x2Fa16DfF6Ca032246f3293B1953C16d8C5715f7B')">
          B-613 {{ $t("合约地址") }}
        </div> -->
        <div class="top-btn ani-move cur" ref="xydz">
          613 {{ $t("协议合约地址") }}
        </div>
        <div class="top-btn ani-move cur" ref="hydz">
          B613 {{ $t("代币地址") }}
        </div>
      </div>
    </div>
    <img
      src="../assets/images/img1.png"
      class="img1 flex-shrink"
    />
  </div>
</template>

<script>
import { copyLink } from "@/utils";
import Clipboard from 'clipboard';
import { mapGetters } from "vuex";
export default {
  name: "TopHead",
  computed: {
    ...mapGetters(["lang", "webInfo"]),
  },
  data(){
    return {
      xydz:null,
      hydz:null
    }
  },
  mounted(){
    this.xydz = new Clipboard(this.$refs.xydz, {
        text: () => '0x6b05b435e4240E606a841d26dBB54ad78055B613'
      });
      this.xydz.on('success', (e) => {
        this.showToast(this.$t('已复制'),'success')
      });

      this.hydz = new Clipboard(this.$refs.hydz, {
        text: () => '0x2Fa16DfF6Ca032246f3293B1953C16d8C5715f7B'
      });
      this.hydz.on('success', (e) => {
        this.showToast(this.$t('已复制'),'success')
      });
  },
  methods: {
    jump(url) {
      window.open(url);
    },
    jumpWhitePaper() {
      if (this.lang == "zh") {
        window.open(this.webInfo.white_paper_cn);
      } else {
        window.open(this.webInfo.white_paper_en);
      }
    },
    jumpDocument() {
      if (this.lang == "zh") {
        window.open(this.webInfo.document_cn);
      } else {
        window.open(this.webInfo.document_en);
      }
    },
    jumpXieyi() {
      window.open("https://s3.613protocol.com/613/cn_3.pdf");
    },
    jumpJieDian() {
      window.open(
        "https://s3.613protocol.com/613/613%E5%8D%8F%E8%AE%AE%E5%85%A8%E7%90%83%E8%8A%82%E7%82%B9%E6%8B%9B%E5%8B%9F%E8%AE%A1%E5%88%92%20.png"
      );
    },
    copydata(data){
      const clipboard = new Clipboard(this.$refs.xydz, {
        text: () => data
      });
      clipboard.on('success', (e) => {
        this.showToast(this.$t('已复制'),'success')
        clipboard.destroy(); // 销毁实例
      });
    }
  },
};
</script>

<style lang="scss">
.img1 {
  max-width: 100%;
  width: 4.47rem;
  height: 5.5rem;
  animation: img1 0.5s linear, topImg 3s linear infinite;
}
.top {
  padding-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .top-title {
    font-size: 0.58rem;
    font-weight: bold;
    opacity: 0;
    animation: topTitle 0.5s linear forwards;
  }
}
.top-btn {
  background-color: #00e36f;
  font-weight: bold;
  color: #131513;
  height: 0.56rem;
  line-height: 0.56rem;
  border-radius: 0.28rem;
  padding: 0 0.3rem;
  font-size: 0.16rem;
  margin-right: 0.06rem;
  margin-bottom: 0.1rem;
}

@keyframes topTitle {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes img1 {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes topImg {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-0.1rem);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0.1rem);
  }
  100% {
    transform: translateY(0);
  }
}
@media (min-width:768px) {
    .tophead{
      flex-wrap: nowrap !important;
    }
}
@media (max-width:768px) {
  .tophead{
      flex-wrap: wrap !important;
    }
}
</style>